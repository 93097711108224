import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import { ContactForm, Testimonials } from "../../components/index"
import {
  Container,
  Wrapper,
  Column,
  Text,
  Button,
  SEOTitle,
  Row,
} from "../../theme"
import { Responsive, Colors } from "../../theme/styles"
import { CallToAction } from "../../components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { MdLaptop } from "react-icons/md"
import { IoMdRocket } from "react-icons/io"
import { FaPenFancy, FaDollarSign } from "react-icons/fa"

const StyledImg = styled(BgImage)`
  ${Responsive.sm`
  height: ${props => (props.arrow ? "100%" : "100%")};
  width: ${props => (props.arrow ? "100%" : "auto")};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::after {
    background-position: center;
    background-size: contain;
  }
  `}
  ${Responsive.md`
  height: ${props => (props.arrow ? "100%" : "100%")};
   `}
   ${Responsive.lg`
   height: ${props => (props.arrow ? "500px" : "100%")};
    `}
    ${Responsive.xlg`
    height: ${props => (props.arrow ? "400px" : "100%")};
     `}
`

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 320px);
gap: 45px;
width: 100%;
justify-content: center;
padding-bottom: 75px;
max-width: 100%;
`}
  ${Responsive.md`
display: grid;
grid-template-columns: repeat(auto-fit, 600px);
gap: 15px;
max-width: 1400px;
margin: 0px auto 0 auto;
justify-content: center;
`}
  ${Responsive.lg`
  display: grid;
  grid-template-columns: repeat(auto-fit,55% 45%);
  gap: 15px;
  max-width: 1400px;
  margin: 0px auto 0 auto;
  justify-content: center;
`}
  ${Responsive.xlg`
display: grid;
grid-template-columns: repeat(auto-fit,60% 40%);
gap: 45px;
max-width: 1400px;
margin: 0px auto 0 auto;
justify-content: center;
`}
`

const StyledText = styled(Text)`
  text-align: left;
  font-weight: ${props => (props.bold ? "bold" : "400")};
  margin-bottom: ${props => (props.marginbottom ? "10px" : 0)};
`

const Dot = styled.div`
  border-radius: 50%;
  width: 29px;
  height: 29px;
  background-color: ${Colors.lightBlue};
`

const PointGrid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
gap: 15px;
width: 100%;
margin-top: 40px;
justify-content: center;
`}
  ${Responsive.md`
grid-template-columns: repeat(auto-fit, 250px);
margin-bottom: 40px;
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: repeat(auto-fit, 225px);
gap: 35px;
width: 100%;
margin-top: 60px;
justify-content: flex-start;
margin-bottom: 0px;
`}
  ${Responsive.xlg`
display: grid;
grid-template-columns: repeat(auto-fit, 325px);
gap: 35px;
width: 100%;
margin-top: 60px;
justify-content: flex-start;
`}
`

const TitleRow = styled(Row)`
  ${Responsive.sm`
justify-content: flex-start;
margin-right: 0px;
`}
  ${Responsive.lg`
justify-content: flex-start;
margin-right: 150px;
`}
`

const ProcessGrid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(1, 1fr);
max-width: 820px;
margin-top: 50px;
align-items: center;
grid-gap: 35px;
justify-content: flex-start;
margin-bottom: 0;
`}
  ${Responsive.md`
display: grid;
grid-template-columns: repeat(2, 50%);
margin-top: 75px;
align-items: center;
justify-content: center;
margin-bottom: 50px;
`}
  ${Responsive.lg`
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
grid-gap: 55px;
margin-bottom: 0;
`}
  ${Responsive.xlg`
grid-template-columns: repeat(auto-fit, 380px);
max-width: 1400px;
margin-bottom: 0;
`}
`

const IconGrid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
grid-gap: 20px;
justify-content: center;
align-items: center;
margin: 30px auto 0 auto;
width: 100%;
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
grid-gap: 20px;
justify-content: center;
align-items: center;
margin: 30px auto 0 auto;
width: 90%;
`}
`

const FinalColumn = styled(Column)`
  ${Responsive.sm`
    align-items: flex-start;
    margin: 0;
  `}
  ${Responsive.lg`
    align-items: flex-start;
    margin: 0 0 0 50px;
`}
`
const data = [
  {
    service: "Technical Analysis",
    list1:
      "Learn how your website is performing with a metric analysis measuring site speed, SEO, and overall performance ",
  },
  {
    service: "Copywriting Overview",
    list1:
      "Are you speaking to your audience? Get a copy review so you can better resonate with your website visitors.",
  },
  {
    service: "Website Analytics Overview",
    list1:
      "We'll dive into your analytics dashboard and find where you could be improving.",
  },
  {
    service: "Setting Goals",
    list1:
      "What's the goal of your website? You and I will sit down and determine the best goal that we want your website visitors to achieve while on your site.",
  },
]

const WebsiteConsultation = () => {
  const bgImage = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "landingbarimage.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      arrow: file(relativePath: { eq: "arrow3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const bar = getImage(bgImage.background.childImageSharp.gatsbyImageData)
  const arrow = getImage(bgImage.arrow.childImageSharp.gatsbyImageData)

  return (
    <Layout
      type="consultation"
      title="Take Away The Headache"
      subtitle="Work directly with CheshTech CEO Will Cheshier for a complete 1-on-1 website analysis for just $150"
    >
      <Wrapper>
        <Container>
          <Column>
            <Grid>
              <Column>
                <TitleRow>
                  <SEOTitle black margin="0 auto 0 auto">
                    Transform your website in a 1-on-1 consultation with
                    CheshTech CEO and Web Strategiest Will Cheshier all for just
                    $150
                  </SEOTitle>
                </TitleRow>
                <PointGrid>
                  {data.map((key, index) => {
                    return (
                      <Row key={index} width="100%">
                        <Column margin="0 10px 0 0" alignitems="flex-start">
                          <Dot />
                        </Column>
                        <Column alignitems="flex-start">
                          <StyledText bold marginbottom>
                            {key.service}
                          </StyledText>
                          <StyledText>{key.list1}</StyledText>
                        </Column>
                      </Row>
                    )
                  })}
                </PointGrid>
              </Column>
              <Column>
                <ContactForm width="100%" />
              </Column>
            </Grid>
          </Column>
        </Container>
        <StyledImg image={bar}>
          <Column width="100%" margin="50px 0">
            <SEOTitle white>What You'll Learn</SEOTitle>
            <IconGrid>
              <Column height="100%">
                <MdLaptop size={30} color={Colors.white} />
                <Text
                  textAlign="center"
                  margin="15px 0 0 0"
                  white
                  fontweight="bold"
                >
                  Understand Web Metrics
                </Text>
              </Column>
              <Column height="100%">
                <IoMdRocket size={30} color={Colors.white} />
                <Text
                  textAlign="center"
                  margin="15px 0 0 0"
                  white
                  fontweight="bold"
                >
                  How to Optimize Website Performance
                </Text>
              </Column>
              <Column height="100%">
                <FaPenFancy size={30} color={Colors.white} />
                <Text
                  textAlign="center"
                  margin="15px 0 0 0"
                  white
                  fontweight="bold"
                >
                  Improved Copywriting
                </Text>
              </Column>
              <Column height="100%">
                <FaDollarSign size={30} color={Colors.white} />
                <Text
                  textAlign="center"
                  margin="15px 0 0 0"
                  white
                  fontweight="bold"
                >
                  Higher Conversion Rates
                </Text>
              </Column>
            </IconGrid>
          </Column>
        </StyledImg>
        <Column margin="50px 0 0 0">
          <SEOTitle>The Process</SEOTitle>
          <StyledImg image={arrow} arrow>
            <Container>
              <ProcessGrid>
                <Column alignitems="flex-start">
                  <Column alignitems="flex-start">
                    <StyledText bold marginbottom>
                      Fill Out The Form
                    </StyledText>
                    <StyledText>
                      You'll fill out the form above with information regarding
                      your website, contact info, and anything else you want me
                      to know beforehand
                    </StyledText>
                  </Column>
                  <Column alignitems="flex-start" margin="30px 0 0 0">
                    <StyledText bold marginbottom>
                      Schedule Consultation
                    </StyledText>
                    <StyledText>
                      We'll block out a 1-2 hour period and schedule our
                      consultation process
                    </StyledText>
                  </Column>
                </Column>
                <Column alignitems="flex-start" height="100%">
                  <Column alignitems="flex-start">
                    <StyledText bold marginbottom>
                      Review Your Website
                    </StyledText>
                    <StyledText>
                      We'll review your website UI/UX, analytics, copywriting,
                      and performance metrics
                    </StyledText>
                  </Column>
                  <Column alignitems="flex-start" margin="30px 0 0 0">
                    <StyledText bold marginbottom>
                      Recommendations
                    </StyledText>
                    <StyledText>
                      At the end of the consultation, I'll give you a list of
                      recommendations so you can improve your website
                    </StyledText>
                  </Column>
                </Column>
                <FinalColumn>
                  <StyledText bold marginbottom>
                    Start Making Money
                  </StyledText>
                  <StyledText>
                    Implement the recommendations and watch as your website gets
                    you more leads as a 24/7 sales and marketing machine!
                  </StyledText>
                </FinalColumn>
              </ProcessGrid>
            </Container>
          </StyledImg>
          <Row justifycontent="center" width="100%" margin="30px auto 0 auto">
            <Link to="/contact">
              <Button background={Colors.lightBlue}>Fill Out Form</Button>
            </Link>
          </Row>
        </Column>
        <Testimonials type="landingpage" />
      </Wrapper>
      <CallToAction type="landingpage" />
    </Layout>
  )
}

export default WebsiteConsultation
